import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import * as indexStyles from './index.module.scss'


const IndexPage = () => (
  <Layout>
    <div className={indexStyles.infoBox}>
    <h2>I am an experienced Full Stack Engineer living in London and working in the exciting world of banking.</h2>
      <p>With +5 years of experience in the financial industry within agile frameworks in large organisations as well as startups, I currently work primarily with JavaScript, Typescript and React and have previous experience of working in Java and SQL.</p>
      <p>Software development is my passion, and I have made a determined effort to improve my skills in order to deliver high enterprise quality code while seeking a harmonious balance between functionality, accessibility and aesthetics.</p>
      <p>With a Design degree and strong background in systems engineering, I have a special interest in information design, customer experience and usability.</p>
      <p>You can check out my <a href='https://github.com/Fercas123' target='_blank' rel='noopener noreferrer'>Github</a> and <a href='https://codepen.io/fercas_123' target='_blank' rel='noopener noreferrer'>CodePen</a> to see more projects I've worked on and contact me on my <a href='https://www.linkedin.com/in/fercas123/' target='_blank' rel='noopener noreferrer'>LinkedIn</a>.</p>
    </div>
  </Layout>
)

export default IndexPage
export const Head = () => (
  <SEO title="Fernanda Castillo" />
  )