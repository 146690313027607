import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import * as headerStyles from './header.module.scss'

const isPartiallyActive = ({
  isPartiallyCurrent
}) => {
  return isPartiallyCurrent
    ? { className: headerStyles.activeNavItem }
    : null
}

const PartialNavLink = props => (
  <Link getProps={isPartiallyActive} {...props} />
)

const Header = ({ siteTitle }) => (
  <header className={headerStyles.header}>
    <div className={headerStyles.wrapper}>
      <h1 style={{ margin: 0 }}>
        <Link to="/">{siteTitle}</Link>
      </h1>
      <nav>
        <ul className={headerStyles.mainNav}>
          <li><Link activeClassName={ headerStyles.activeNavItem } to='/'>Home</Link></li>
          <li><PartialNavLink activeClassName={ headerStyles.activeNavItem } to='/blog'>Blog</PartialNavLink></li>
        </ul>
      </nav>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
