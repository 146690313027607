import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Header from './header'
import Footer from './footer'
import '../styles/main.scss'
import * as layoutStyles from './layout.module.scss'


interface Props {
  children?: React.ReactNode,
  background: boolean,
  expand: boolean
}

const Layout = ({ background, expand, children }: Props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title, 
          author
        }
      }
    }
  `)

  useEffect(() => {
    const links = document.querySelectorAll('a, li a');
    const images = document.querySelectorAll('img');
    let cursor:HTMLElement = document.querySelector('.cursor') as HTMLElement;
    if (cursor) {
      window.addEventListener('mousemove', (e) => {
          cursor.style.top = e.pageY + 'px';
          cursor.style.left = e.pageX + 'px';
      });
      links.forEach(l => {
        l.addEventListener('mouseleave', () => { cursor.classList.remove('cursor-link') });
        l.addEventListener('mouseover', () => { cursor.classList.add('cursor-link') });
      });
      images.forEach(l => {
        l.addEventListener('mouseleave', () => { cursor.classList.remove('cursor-over-image') });
        l.addEventListener('mouseover', () => { cursor.classList.add('cursor-over-image') });
      });
    }
  }, []);


  return <>
      {(background) ? <div className={layoutStyles.background}/> : null}
      <div className={layoutStyles.container}>
        <Header siteTitle={data.site.siteMetadata.title} />
        <main className={expand ? layoutStyles.contentExpand : layoutStyles.content}>{children}</main>
      </div>
      <Footer siteAuthor={data.site.siteMetadata.author} />
      <div className='cursor' />
    </>
}

export default Layout
