import React from "react"
import * as footerStyles from './footer.module.scss'

const Footer = ({ siteAuthor }) => {
  return (
    <footer className={footerStyles.footer}>
      Created by {siteAuthor}, © {new Date().getFullYear()}, Built with
      {` `}
      <a href="https://www.gatsbyjs.org">Gatsby</a>
    </footer>
  )
}


export default Footer
